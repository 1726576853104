<template>
  <div class="wrapper-main__content requests">
    <div class="form-content">
      <div class="d-flex flex-wrap border-bottom py-2">
        <div class="d-flex align-items-center mb-3">
          <h1 class="h3">{{ $t('sideBar.requests') }}/</h1>
          <router-link to="logs" class="h3">
            {{ $t('sideBar.logs') }}
          </router-link>
          <small v-if="paginationData && paginationData" tabindex="-1" class="form-text text-muted ml-3">
            {{ $t('title.showing') }}
            {{ parseInt(paginationData.total) >= parseInt(paginationData.per_page) ? paginationData.per_page : paginationData.total }} {{ $t('title.of') }}
            {{ paginationData.total }} {{ $t('title.entries') }}
          </small>
        </div>
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between gap-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
            <b-form-select v-model="filters.selectedHTTPMethod" :options="HTTPMethodsList" class="w-auto" @change="pageClick(1)" />
            <b-form-select v-model="filters.selectedStatus" :options="requestStatusesList" class="w-auto" @change="pageClick(1)" />
            <MultiSelect
              v-model="filters.selectedUser"
              label="name"
              track-by="id"
              :options="filters.usersList"
              class="w-270px"
              :placeholder="$t('table.selectUser')"
              @input="pageClick(1)"
            />
          </div>

          <search-input
            v-model="searchElements"
            :show-selector="true"
            :selected-search-type.sync="searchType"
            :options="searchFieldsList"
            @searchTable="pageClick(1)"
          />
        </div>
        <div class="d-flex gap-3 w-100 mt-2">
          <MultiSelect
            id="ajax-commercials"
            v-model="filters.selectedCommercial"
            class="w-270px"
            label="name"
            track-by="id"
            :placeholder="$t('table.commercial')"
            :options="filters.commercialsList"
            :loading="filters.isLoadingCommercialsList"
            :internal-search="false"
            :options-limit="100"
            @search-change="asyncFindCommercials"
            @input="pageClick(1)"
          >
          </MultiSelect>

          <MultiSelect
            v-model="filters.selectedChannel"
            class="w-270px"
            :options="filters.channelsList"
            :placeholder="$t('channelModal.channel')"
            label="name"
            track-by="id"
            @input="pageClick(1)"
          ></MultiSelect>
        </div>
      </div>
      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover" style="white-space: nowrap">
          <thead>
            <th scope="col" style="min-width: 50px">
              <sort-arrows :sort-string="sortString" :column="'finished_at,'" @click="sortTable('finished_at,', $event)" />
              {{ $t('table.date') }}, {{ $t('table.time') }}
            </th>
            <th>{{ $t('table.duration') }}</th>
            <th>{{ $t('table.status') }}</th>
            <th>{{ $t('table.user') }}</th>
            <th>{{ $t('table.request') }}</th>
            <th>{{ $t('table.requestId') }}</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-if="requestsListStatus !== 'success'">
              <td colspan="7"><SpinnerLoader :loading="requestsListStatus" /></td>
            </tr>
            <tr v-for="(row, index) in tableData" :key="index">
              <td>
                {{ row.started_at ? new Date(row.started_at).toLocaleString() : '' }} - {{ row.finished_at ? new Date(row.finished_at).toLocaleString() : '' }}
              </td>

              <td>{{ row.duration }}</td>
              <td>{{ row.status }}</td>
              <td>
                <router-link
                  v-if="row.user"
                  :to="`users?q=${row.user.login}&search_type=login`"
                  :title="`${$t('table.clickToOpenOnUsersPage')}. \n${$t('table.userData')}: \n${row.user.email} \n${row.user.login}`"
                >
                  {{ row.user.name }}
                </router-link>
              </td>
              <td>
                <BIconCheck v-if="/2\d\d/.test(row.http_status)" class="text-success" :title="$t('alert.success')" />
                <BIconExclamationTriangle v-else-if="/4\d\d/.test(row.http_status)" class="text-danger" :title="$t('alert.validationError')" />
                <BIconExclamationCircle v-else-if="/5\d\d/.test(row.http_status)" class="text-danger" :title="$t('alert.serverError')" />
                [{{ row.http_status }}] {{ row.http_method }} {{ row.route }}
              </td>
              <td>
                <router-link v-if="row.request_id" :to="`logs?search_type=request_id&q=${row.request_id}`" title="Open on Logs page">
                  {{ row.request_id }}
                </router-link>
              </td>
              <td>
                <b-button variant="outline-primary" size="sm" @click="loadRequestInfo(row.request_id)">{{ $t('table.openVerb') }}</b-button>
              </td>
            </tr>
            <tr v-if="requestsListStatus === 'success' && tableData && tableData.length === 0">
              <td colspan="7">
                <div class="d-flex w-100 justify-content-center">
                  {{ $t('table.noContent') }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--TABLE-->
    </div>

    <b-modal
      id="request-details-modal"
      :title="$t('table.requestDetails')"
      :ok-title="$t('table.close')"
      :cancel-title="$t('table.cancel')"
      :busy="requestDataStatus === 'loading'"
      size="lg"
      scrollable
    >
      <SpinnerLoader v-if="requestDataStatus !== 'success'" :loading="requestDataStatus" />
      <div v-else-if="requestData && requestData.data" class="overflow-auto">
        <details v-if="requestData.data.request_json">
          <summary>{{ $t('table.requestBody') }}</summary>
          <pre style="white-space: pre-wrap">{{ requestData.data.request_json }}</pre>
        </details>
        <details v-if="requestData.data.request_query">
          <summary>{{ $t('table.requestQuery') }}</summary>
          <pre style="white-space: pre-wrap">{{ requestData.data.request_query }}</pre>
        </details>
        <details v-if="requestData.data.response_json">
          <summary>{{ $t('table.response') }}</summary>
          <pre style="white-space: pre-wrap">{{ requestData.data.response_json }}</pre>
        </details>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SpinnerLoader from '@/components/SpinnerLoader';
import PaginateWrapper from '@/components/PaginateWrapper';
import SearchDropdown from '@/components/SearchDropdown';
import SearchInput from '@/components/SearchInput';
import { mapGetters } from 'vuex';
import requests from '@/store/requests/index';
import SortArrows from '@/components/SortArrows';
import sortTableMixin from '@/mixins/sortTable';
import convertDate from '@/filters/convertDate';
import MultiSelect from '@/components/MultiSelect';
import { BIconCheck, BIconExclamationCircle, BIconExclamationTriangle } from 'bootstrap-vue';
import { getCommercials } from '@/api/apiServices';
import debounce from '@/utils/debounce';

export default {
  name: 'Requests',
  components: {
    SpinnerLoader,
    PaginateWrapper,
    SearchDropdown,
    SearchInput,
    MultiSelect,
    SortArrows,
    BIconCheck,
    BIconExclamationCircle,
    BIconExclamationTriangle,
  },
  filters: { convertDate },
  mixins: [sortTableMixin],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
    search_type: { type: String, default: undefined },
    selected_http_method: { type: String, default: undefined },
    selected_status: { type: String, default: undefined },
    selected_user: { type: [String, Number], default: undefined },
    selected_channel: { type: [String, Number], default: undefined },
    selected_commercial: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      paginationData: null,
      searchElements: '',
      sortString: '-finished_at,',
      filterPage: 1,
      selectedPerPage: '50',
      searchType: 'request_id',
      tableData: [],
      filters: {
        selectedHTTPMethod: '',
        selectedStatus: '',

        selectedUser: null,
        usersList: [],

        selectedCommercial: null,
        commercialsList: [],
        isLoadingCommercialsList: false,

        selectedChannel: null,
        channelsList: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      requestsList: 'getRequestsList',
      requestData: 'getRequestData',
      requestsListStatus: 'getRequestsListStatus',
      requestDataStatus: 'getRequestDataStatus',
      channels: 'getChannelsList',
      users: 'getUsers',
    }),
    searchFieldsList() {
      return [
        { value: 'request_id', text: this.$i18n.t('table.requestId') },
        { value: 'route', text: this.$i18n.t('table.request') },
        { value: 'http_status', text: this.$i18n.t('table.httpStatus') },
        { value: 'spot_id', text: this.$i18n.t('table.spotId') },
        { value: 'commercial_id', text: this.$i18n.t('table.commercialId') },
        { value: 'block_id', text: this.$i18n.t('table.blockId') },
      ];
    },
    HTTPMethodsList() {
      return [
        { value: '', text: this.$i18n.t('table.selectMethod'), disabled: true },
        { value: null, text: this.$i18n.t('table.all') },
        { value: 'GET', text: 'GET' },
        { value: 'POST', text: 'POST' },
        { value: 'PUT', text: 'PUT' },
        { value: 'DELETE', text: 'DELETE' },
        { value: 'PATCH', text: 'PATCH' },
      ];
    },
    requestStatusesList() {
      return [
        { value: '', text: this.$i18n.t('table.selectStatus'), disabled: true },
        { value: null, text: this.$i18n.t('table.all') },
        { value: 'SUCCESS', text: 'SUCCESS' },
        { value: 'FAILURE', text: 'FAILURE' },
        { value: 'IN_PROGRESS', text: 'IN PROGRESS' },
      ];
    },
  },
  watch: {
    requestsList() {
      this.setTableData();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule('requests')) this.$store.registerModule('requests', requests);
  },
  created() {
    document.title = this.$i18n.t('sideBar.requests') + ' – OpenMediaLogic';
  },
  mounted() {
    this.restoreProps();
  },
  destroyed() {
    this.$store.commit('clearRequests');
    this.$store.commit('clearUsersList');
    this.$store.commit('clearCommercialsList');
    this.$store.commit('clearChannelsList');
    if (!module.hot && this.$store.hasModule('requests')) this.$store.unregisterModule('requests'); // don't unregister when HMR
  },
  methods: {
    async restoreProps() {
      //restore props
      await Promise.all([this.$store.dispatch('GET_USERS', { per_page: 2000 }), this.$store.dispatch('GET_CHANNELS', { per_page: 1000 })]);
      if (this.users?.data) {
        this.filters.usersList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.users.data];
      }
      if (this.channels) {
        this.filters.channelsList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.channels];
      }
      if (this.q) this.searchElements = this.q;
      if (this.on_page) this.filterPage = +this.on_page;
      if (this.per_page && this.per_page != '10') this.selectedPerPage = this.per_page;
      if (this.sort_mode) {
        this.sortString = this.sort_mode;
        if (this.sortString && this.sortString.slice(-1) !== ',') this.sortString += ',';
      }
      if (this.search_type) this.searchType = this.search_type;
      if (this.selected_http_method) this.filters.selectedHTTPMethod = this.HTTPMethodsList.find((e) => e.value === this.selected_http_method)?.value;
      if (this.selected_status) this.filters.selectedStatus = this.requestStatusesList.find((e) => e.value === this.selected_status)?.value;
      if (this.selected_user) this.filters.selectedUser = this.filters.usersList.find((u) => u.id === +this.selected_user);
      if (this.selected_channel) this.filters.selectedChannel = this.filters.channelsList.find((u) => u.id === +this.selected_channel);
      if (this.selected_commercial) {
        this.searchType = 'commercial_id';
        this.searchElements = this.selected_commercial;
      }

      this.paramsData();
    },

    async paramsData() {
      if (this.tableData) this.tableData = [];
      await this.$store.dispatch('GET_REQUESTS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        sort: this.sortString,
        'filter[request_id]': this.searchElements !== '' && this.searchType === 'request_id' ? this.searchElements : null,
        'filter[route]': this.searchElements !== '' && this.searchType === 'route' ? this.searchElements : null,
        'filter[http_status]': this.searchElements !== '' && this.searchType === 'http_status' ? this.searchElements : null,
        'filter[http_method]': this.filters.selectedHTTPMethod || undefined,
        'filter[status]': this.filters.selectedStatus || undefined,
        'filter[spot_id]': this.searchElements !== '' && this.searchType === 'spot_id' ? this.searchElements : null,
        'filter[block_id]': this.searchElements !== '' && this.searchType === 'block_id' ? this.searchElements : null,
        'filter[commercial_id]': this.filters.selectedCommercial?.id
          ? this.filters.selectedCommercial.id
          : this.searchElements !== '' && this.searchType === 'commercial_id'
          ? this.searchElements
          : null,
        'filter[user->id]': this.filters.selectedUser?.id,
        'filter[channel_id]': this.filters.selectedChannel?.id,
      });
      this.updateQuery();
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },

    setTableData() {
      if (this.requestsList) {
        this.tableData = this.requestsList.data;
        this.paginationData = this.requestsList.meta;
        if (this.filterPage > this.requestsList.meta.last_page) {
          this.filterPage = this.requestsList.meta.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },

    updateQuery() {
      if (
        this.$route.query.q !== this.searchElements ||
        this.$route.query.on_page != this.filterPage ||
        this.$route.query.per_page != this.selectedPerPage ||
        this.$route.query.sort != this.sortString ||
        this.$route.query.search_type != this.searchType ||
        this.$route.query.selected_http_method != this.filters.selectedHTTPMethod ||
        this.$route.query.selected_status != this.filters.selectedStatus ||
        this.$route.query.selected_user != this.filters.selectedUser?.id ||
        this.$route.query.selected_channel != this.filters.selectedChannel?.id ||
        this.$route.query.selected_commercial != this.filters.selectedCommercial?.id ||
        (this.searchType === 'commercial_id' && this.searchElements !== '' && this.$route.query.selected_commercial != this.searchElements)
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            q: this.searchElements,
            sort: this.sortString,
            on_page: this.filterPage,
            per_page: this.selectedPerPage,
            search_type: this.searchType,
            selected_http_method: this.filters.selectedHTTPMethod,
            selected_status: this.filters.selectedStatus,
            selected_user: this.filters.selectedUser?.id,
            selected_channel: this.filters.selectedChannel?.id,
            selected_commercial: this.filters.selectedCommercial?.id
              ? this.filters.selectedCommercial.id
              : this.searchElements !== '' && this.searchType === 'commercial_id'
              ? this.searchElements
              : undefined,
          },
        });
      }
    },

    async loadRequestInfo(id) {
      this.$bvModal.show('request-details-modal');
      await this.$store.dispatch('GET_REQUEST_ID', {
        id,
        params: { visible: 'request_query,request_json,response_json' },
      });
    },

    asyncFindCommercials: debounce(function (query) {
      if (query) {
        this.filters.isLoadingCommercialsList = true;
        getCommercials({ 'filter[name]': query }).then((response) => {
          this.filters.commercialsList = response.data?.data ? [{ name: this.$i18n.t('table.all'), id: null }, ...response.data.data] : [];
          this.filters.isLoadingCommercialsList = false;
        });
      }
    }, 700),
  },
};
</script>

<style lang="sass">
.wrapper-main__content.requests .vocabulary-table
  max-height: calc(100vh - 201px)
</style>
