// VUEX DYNAMIC STORE MODULE
import Vue from 'vue';
import { getRequests, getRequestId } from '../../api/apiServices';

export default {
  state: {
    requestsList: null,
    requestsListStatus: '',

    requestData: null,
    requestDataStatus: '',
  },
  mutations: {
    setRequestsListData(state, payload) {
      state.requestsListStatus = payload[0];
      Vue.set(state, 'requestsList', payload[1] || null);
    },
    setRequestData(state, payload) {
      state.requestDataStatus = payload[0];
      Vue.set(state, 'requestData', payload[1] || null);
    },

    clearRequests: (state) => {
      state.requestsList = null;
      state.requestsListStatus = '';
      state.requestData = null;
      state.requestDataStatus = '';
    },
  },
  actions: {
    // REQUESTS
    async GET_REQUESTS({ dispatch, commit }, payload) {
      commit('setRequestsListData', ['loading']);
      await getRequests(payload)
        .then((res) => commit('setRequestsListData', ['success', res.data]))
        .catch((e) => commit('setRequestsListData', ['error']));
    },

    async GET_REQUEST_ID({ dispatch, commit }, payload) {
      commit('setRequestData', ['loading']);
      await getRequestId(payload.id, payload.params)
        .then((res) => commit('setRequestData', ['success', res.data]))
        .catch((e) => commit('setRequestData', ['error']));
    },
  },
  getters: {
    getRequestsList: (state) => state.requestsList,
    getRequestData: (state) => state.requestData,
    getRequestsListStatus: (state) => state.requestsListStatus,
    getRequestDataStatus: (state) => state.requestDataStatus,
  },
};
